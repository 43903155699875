import React, { FC } from 'react'
import { Field, ErrorMessage } from 'formik'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
const Step1: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Basic Details</h2>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Title</label>
        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='accountName'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='businessName' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Author</label>
        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='accountName'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='businessName' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <div className="image-input-wrapper h-30px">
          <label className='fs-6 fw-bold form-label required'>Add Image</label> 
        </div>
        <div className="image-input image-input-empty" data-kt-image-input="true" style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`, }}>

          <div className="image-input-wrapper w-125px h-125px"></div>

          <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
            data-kt-image-input-action="change"
            data-bs-toggle="tooltip"
            data-bs-dismiss="click"
            title="Change avatar">
            <i className="bi bi-pencil-fill fs-7"></i>

            <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
            <input type="hidden" name="avatar_remove" />

          </label>

          <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
            data-kt-image-input-action="cancel"
            data-bs-toggle="tooltip"
            data-bs-dismiss="click"
            title="Cancel avatar">
            <i className="bi bi-x fs-2"></i>
          </span>

          <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow"
            data-kt-image-input-action="remove"
            data-bs-toggle="tooltip"
            data-bs-dismiss="click"
            title="Remove avatar">
            <i className="bi bi-x fs-2"></i>
          </span>

        </div>

      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className='required'>Description (HTML text aditor is not working so this is pending for dev)</span>
        </label>

        <Field
          as='textarea'
          name='businessDescription'
          className='form-control form-control-lg form-control-solid'
          rows={3}
        ></Field>
        <div className='text-danger mt-2'>
          <ErrorMessage name='businessDescriptor' />
        </div>

        <div className='form-text'>
          Customers will see this shortened version of your statement descriptor
        </div>
      </div>

      <div className='d-flex flex-stack mb-15'>
        <div className='me-5'>
          <label className='fs-6 fw-bold form-label'>Add Action Button</label>
          <div className='fs-7 fw-bold text-gray-400'> Add a button for user to click and navigate to a specific page/website </div>
        </div>

        <label className='form-check form-switch form-check-custom form-check-solid'>
          <Field className='form-check-input' type='checkbox' value='1' checked={true} />
        </label>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Button Label</label>
        <input type="text" className='form-control form-control-lg form-control-solid'/>
        <div className='text-danger mt-2'>
          <ErrorMessage name='businessName' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Add Link</label>
        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='accountName'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='businessName' />
        </div>
      </div>


    </div>
  )
}

export { Step1 }
