import React, { useState } from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'




export function SignInPasswordResetConfirmation() {


    return (

        <div>

            <div className="pt-lg-10 mb-10">

                <h1 className="fw-bolder fs-2qx text-gray-800 mb-7 align-center">Password is changed</h1>

                <div className="fw-bold fs-3 text-muted mb-15 align-center">Your password is successfully changed. <br />Please sign in to your account.</div>

                <div className="text-center">
                    <a href="../../demo1/dist/authentication/flows/basic/sign-in.html" className="btn btn-primary btn-lg fw-bolder">Sign In</a>
                </div>

                {/* <div className="text-gray-700 fw-bold fs-4 pt-7 align-center">Did’t receive an email?
                    <a href="../../demo1/dist/authentication/flows/basic/password-reset.html" className="text-primary fw-bolder"> Try Again</a></div> */}

            </div>

        </div>

    )
}



